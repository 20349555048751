/* eslint-disable no-plusplus */
import { createTheme } from '@material-ui/core/styles';
import { Mulish } from 'next/font/google';
import shadows from './shadows';

export const defaultFont = Mulish({
  subsets: ['latin', 'cyrillic'],
  display: 'swap',
});

export const dimensions = {
  defaultBorderRadius: 7,
  defaultBorderWidth: '0.1rem',
};

export const timings = {
  defaultAnimationTiming: 500,
  slowAnimationTiming: 1000,
  pageTransitionTiming: 750,
};

export const delays = {
  defaultAnimationDelay: 250,
};

export const easings = {
  defaultEasing: 'cubicBezier(0.4, 0.0, 0.2, 1)',
  defaultEasingCss: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
};

export const animationDefaults = {
  defaultScale: 1.05,
};

export const defaultBrandLogoSize = 50;

let BASE_ZINDEX = 100000;
export const zIndices = {};
zIndices.modelRequestForm = BASE_ZINDEX++;
zIndices.proventExpertBar = BASE_ZINDEX++;
zIndices.scrollToTopFab = BASE_ZINDEX++;
zIndices.whatsappFab = BASE_ZINDEX++;
zIndices.floatingActionBar = BASE_ZINDEX++;
zIndices.mobileMainMenu = BASE_ZINDEX++;
zIndices.header = BASE_ZINDEX++;
zIndices.santanderFab = BASE_ZINDEX++;
zIndices.desktopMenuPopover = BASE_ZINDEX++;
zIndices.modal = BASE_ZINDEX++;
zIndices.gdprConsent = BASE_ZINDEX++;
zIndices.reactBnbGallery = BASE_ZINDEX++;
zIndices.nProgress = BASE_ZINDEX++;

const palette = {
  primary: {
    light: 'rgba(255, 153, 0, 0.75)',
    main: '#FF9900',
    dark: '#7F4C00',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#FFFFFF',
    main: '#FFFFFF',
    dark: '#FFFFFF',
    contrastText: '#191919',
  },
  tertiary: {
    light: '#E0E0E0',
    main: '#E0E0E0',
    dark: '#E0E0E0',
    contrastText: '#191919',
  },
  quaternary: {
    light: '#F5F5F5',
    main: '#F5F5F5',
    dark: '#F5F5F5',
    contrastText: '#191919',
  },
  light: {
    light: '#FFFFFF',
    main: '#FFFFFF',
    dark: '#FFFFFF',
    contrastText: '#191919',
  },
  dark: {
    light: '#191919',
    main: '#191919',
    dark: '#191919',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#191919',
    secondary: '#191919',
    disabled: '#F4F4F4',
    hint: '#F4F4F4',
  },
  error: {
    light: '#f00',
    main: '#D50000',
    dark: '#f00',
    contrastText: '#fff',
  },
};

function getResponsiveFontSize(min, max) {
  const theme = createTheme();
  // https://blog.kulturbanause.de/2016/12/fliessend-skalierende-texte-und-schriftgroessen-mit-css-calc/
  return `calc(${min}px + (${max} - ${min}) * ((100vw - ${theme.breakpoints.values.sm}px) / (${theme.breakpoints.values.lg} - ${theme.breakpoints.values.sm})))`;
}

const defaultTheme = createTheme();
const primaryTheme = {
  palette,
  typography: {
    fontFamily: [defaultFont.style.fontFamily, 'sans-serif'].join(', '),
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    button: {
      lineHeight: 1.8,
    },
    display1: {
      fontSize: getResponsiveFontSize(48, 60),
    },
    display2: {
      fontSize: getResponsiveFontSize(40, 50),
    },
    display3: {
      fontSize: getResponsiveFontSize(32, 46),
    },
    h1: {
      fontSize: getResponsiveFontSize(30, 40),
      fontWeight: 700,
    },
    h2: {
      fontSize: getResponsiveFontSize(28, 36),
    },
    h3: {
      fontSize: getResponsiveFontSize(24, 32),
    },
    h4: {
      fontSize: getResponsiveFontSize(22, 28),
    },
    h5: {
      fontSize: getResponsiveFontSize(20, 24),
    },
    h6: {
      fontSize: getResponsiveFontSize(16, 20),
    },
  },
  shape: {
    borderRadius: dimensions.defaultBorderRadius,
  },
  shadows,
  zIndex: {
    modal: zIndices.modal,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 300,
        whiteSpace: 'nowrap',
        textTransform: 'none',
      },
      textSizeSmall: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      textSizeLarge: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      outlined: {
        borderWidth: '2px !important',
      },
      outlinedSecondary: {
        // borderColor: '#FB8C00'
      },
    },
    MuiInputBase: {
      colorSecondary: {
        color: '#FFFFFF',
      },
    },
    MuiInput: {
      root: {
        backgroundColor: palette.text.disabled,
        borderRadius: dimensions.defaultBorderRadius,
        padding: `${defaultTheme.spacing(0.5)}px ${defaultTheme.spacing(1)}px`,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderWidth: '2px !important',
        borderColor: 'inherit',
        transition: `border ${timings.defaultAnimationTiming}ms ${easings.defaultEasingCss}`,
      },
      notchedOutline: {
        borderWidth: '2px !important',
        transition: `border ${timings.defaultAnimationTiming}ms ${easings.defaultEasingCss}`,
      },
      colorSecondary: {
        borderColor: '#FFFFFF',
      },
    },
    MuiInputLabel: {
      root: {
        zIndex: 1,
        pointerEvents: 'none',
        marginLeft: defaultTheme.spacing(1),
        marginTop: defaultTheme.spacing(0.5),
      },
      outlined: {
        marginLeft: 0,
        marginTop: 0,
        paddingLeft: '5px',
        paddingRight: '5px',
        transform: 'translate(12px, 11px) scale(1)',
      },
    },
    MuiSelect: {
      root: {
        padding: `${defaultTheme.spacing(1.25)}px ${defaultTheme.spacing(2)}px !important`,
      },
      select: {
        backgroundColor: 'white',
        borderRadius: `${dimensions.defaultBorderRadius}px !important`,
      },
      outlined: {
        backgroundColor: 'transparent',
        paddingRight: '38px !important',
      },
      icon: {
        transition: `transform ${timings.defaultAnimationTiming}ms ${easings.defaultEasingCss}`,
        top: 'calc(50% - 8px)',
        // transform: 'rotate(180deg) scale(0.85)',
        color: 'inherit',
      },
      iconOutlined: {
        right: 12,
      },
      iconOpen: {
        // transform: 'rotate(0) scale(0.85)'
      },
    },
  },
};
export default createTheme(primaryTheme);
export const hyundaiTheme = createTheme({
  ...primaryTheme,
  palette: {
    ...palette,
    primary: {
      light: 'rgba(1, 44, 95, 0.75)',
      main: '#012c5f',
      dark: '#012c5f',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    ...primaryTheme.typography,
    fontFamily: ['HyundaiSansText', defaultFont.style.fontFamily, 'sans-serif'].join(', '),
  },
});
export const mitsubishiTheme = createTheme({
  ...primaryTheme,
  typography: {
    ...primaryTheme.typography,
    fontFamily: ['MMC', defaultFont.style.fontFamily, 'sans-serif'].join(', '),
  },
});

export const globalStyles = (theme) => ({
  '@global': {
    // Text input
    '.MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiFormLabel-filled': {
      marginTop: 0,
    },
    // FormControl
    '.MuiFormControl-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,0.25)',
    },
    '.MuiFormControl-colorSecondary .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255,255,255,0.45)',
    },
    '.MuiFormControl-colorSecondary .MuiOutlinedInput-notchedOutline:hover, .MuiFormControl-colorSecondary .MuiOutlinedInput-notchedOutline:focus': {
      borderColor: 'rgba(255,255,255,1) !important',
    },
    // Form Control Label
    '.MuiFormLabel-root.MuiInputLabel-outlined': {
      backgroundColor: theme.palette.secondary.main,
    },
    '.MuiFormControl-colorSecondary .MuiFormLabel-root.MuiInputLabel-outlined': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
    },
    // Select
    '.MuiFormControl-colorSecondary .MuiSelect-select': {
      color: theme.palette.primary.contrastText,
    },
    // Select icon
    '.MuiFormControl-colorSecondary .MuiSelect-icon': {
      color: theme.palette.primary.contrastText,
    },
  },
});
