import {
  Box, ListItemIcon, ListItemText,
  MenuItem, Popover, Typography, useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import NextLink from 'next/link';
import { useTranslation } from 'react-i18next';
import Select from '../atoms/Select';
import { LOCALES } from '@/constants';

export default function LanguagePopover(props) {
  const {
    anchorEl,
    onClose,
    handleOpen,
    locales,
    pageUrls,
    open,
    ...other
  } = props;
  const { i18n } = useTranslation();
  const classes = useStyles();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const content = locales.map((item) => (
    <NextLink
      key={item.attributes.code}
      href={
        pageUrls.find((url) => url.locale === item.attributes.code)?.url || '/'
      }
      legacyBehavior
      locale={item.attributes.code}
      passHref
      value={item.attributes.code}
    >
      <MenuItem
        component="a"
        dense
        onClick={onClose}
      >
        <ListItemIcon>
          <Box
            sx={{
              display: 'flex',
              height: 20,
              width: 20,
              '& img': {
                display: 'block',
                width: '100%',
                height: 'auto',
              },
            }}
          >
            <img
              alt={item.attributes.name}
              src={LOCALES[item.attributes.code]?.icon}
            />
          </Box>
        </ListItemIcon>
        <ListItemText
          primary={(
            <Typography variant="subtitle2">
              {item.attributes.name}
            </Typography>
          )}
        />
      </MenuItem>
    </NextLink>
  ));

  if (mdUp) {
    return (
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={onClose}
        open={open}
        transitionDuration={0}
        {...other}
      >
        {content}
      </Popover>
    );
  }

  return (
    <Select
      className={classes.languageSelection}
      fullWidth
      onClose={onClose}
      onOpen={handleOpen}
      open={open}
      size="small"
      value={i18n.language}
      variant="outlined"
    >
      {content}
    </Select>
  );
}

LanguagePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

LanguagePopover.defaultProps = {
  anchorEl: null,
  onClose: null,
  open: false,
};

const useStyles = makeStyles((theme) => ({
  languageSelection: {
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
}));
