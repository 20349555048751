import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Link from '../atoms/Link';

export default function Breadcrumbs({ data }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const items = [
    {
      id: 'home',
      title: 'HOME',
      url: '/',
    },
    ...data,
  ];

  // if (items.length === 1 && items[0].title === 'HOME') {
  //   return (
  //     <MuiBreadcrumbs
  //       aria-label="breadcrumb"
  //       className={classes.root}
  //     >
  //       <Typography variant="caption">
  //         &nbsp;
  //       </Typography>
  //     </MuiBreadcrumbs>
  //   );
  // }

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      className={classes.root}
    >
      {items.map((item, index) => (index < items.length - 1
        ? (
          <Link key={item.id} href={item.url}>
            <Typography component="a" variant="caption">
              {renderTitle(item.title, t)}
            </Typography>
          </Link>
        )
        : (
          <Typography key={item.id} variant="caption">
            {renderTitle(item.title, t)}
          </Typography>
        )))}
    </MuiBreadcrumbs>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '& a:focus, & a:hover': {

    },
  },
}));

function renderTitle(title, t) {
  if (title === 'HOME') {
    return t('components.molecules.Breadcrumbs.homePage');
  }

  if (title.includes('#')) {
    // eslint-disable-next-line prefer-destructuring
    title = title.split('#')[0];
  }

  return title;
}
