import {
  Fab, Fade, Hidden, IconButton,
} from '@material-ui/core';
import { FiArrowUp, FiArrowUpCircle } from 'react-icons/fi';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import throttle from 'lodash/throttle';
import { zIndices } from '../../theme';

export default function ScrollToTopFab({ iconButton }) {
  const classes = useStyles();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = throttle(() => {
    const { scrollY, innerHeight } = window;
    const shouldBeVisible = scrollY > (innerHeight / 2);

    setVisible(shouldBeVisible);
  }, 100);
  const handleScrollToTop = () => {
    scrollToTop();
  };

  if (iconButton) {
    return (
      <IconButton
        aria-label="scroll to top"
        color="primary"
        onClick={handleScrollToTop}
      >
        <Fade in={isVisible}>
          <FiArrowUpCircle />
        </Fade>
      </IconButton>
    );
  }

  return (
    <Hidden mdDown>
      <div className={classes.root}>
        <Fade in={isVisible}>
          <Fab
            aria-label="scroll to top"
            color="primary"
            onClick={handleScrollToTop}
          >
            <FiArrowUp />
          </Fab>
        </Fade>
      </div>
    </Hidden>
  );
}

ScrollToTopFab.propTypes = {
  iconButton: PropTypes.bool,
};
ScrollToTopFab.defaultProps = {
  iconButton: false,
};
const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    right: '3%',
    bottom: 80,
    zIndex: zIndices.scrollToTopFab,
  },
}));

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}
