import {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import debounce from 'lodash/debounce';
import {
  Box, IconButton, InputAdornment, TextField, CircularProgress,
} from '@material-ui/core';
import { FiSearch, FiX } from 'react-icons/fi';
import { makeStyles } from '@material-ui/styles';

export default function SearchBar(props) {
  const {
    t,
    loading,
    autofocus,
    search,
    className,
    onChange,
    onSubmit,
  } = props;
  const classes = useStyles();
  const [newValue, setNewValue] = useState(search ?? '');
  const inputRef = useRef();

  useEffect(() => {
    if (autofocus) {
      inputRef.current.querySelector('input').focus();
    }

    const doSearch = (event) => {
      handleChange({
        target: {
          value: event.detail,
        },
      });
    };
    window.addEventListener('doSearch', doSearch);

    return () => {
      window.removeEventListener('doSearch', doSearch);
    };
  }, []);
  useEffect(() => {
    if (onChange) {
      onChange(newValue);
    }
  }, [newValue]);

  const handleChange = (event) => {
    setNewValue(event.target.value);

    if (newValue && newValue.length >= 3) {
      onSubmitDebounced(newValue);
    }
  };
  const onSubmitDebounced = useCallback(debounce((value) => {
    if (onSubmit) {
      onSubmit(value);
    }

    gtag('event', 'search', {
      event_category: 'engagement',
      event_label: value,
    });
  }, 300), [onSubmit]);
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitDebounced(newValue);
  };
  const handleClear = () => {
    setNewValue('');
    inputRef.current.querySelector('input').focus();
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit}>
        <Box alignItems="center" display="flex">
          <TextField
            ref={inputRef}
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: classes.input,
              endAdornment: newValue ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear} size="small">
                    <FiX />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            onChange={handleChange}
            placeholder={t('components.molecules.SearchBar.search')}
            value={newValue}
          />
          <IconButton
            type="submit"
          >
            {loading ? (
              <CircularProgress
                size={24}
              />
            ) : (
              <FiSearch />
            )}
          </IconButton>
        </Box>
      </form>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  input: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(244,244,244, 1)',
    '@supports (backdrop-filter: blur(20px))': {
      backdropFilter: 'saturate(180%) blur(20px)',
      backgroundColor: 'rgba(244,244,244, 0.75)',
    },
  },
}));

function gtag() {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}
