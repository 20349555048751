import { List } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';
import Container from '../../atoms/Container';
import DesktopMenuItem from './DesktopMenuItem';

export default function DesktopMenu(props) {
  const { items, onClick } = props;
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <List
        className={classes.container}
        component="nav"
        disablePadding
      >
        {items.map((item) => (
          <DesktopMenuItem
            key={item.path}
            item={item}
            level={1}
            onClick={onClick}
          />
        ))}
      </List>
    </Container>
  );
}

DesktopMenu.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};
DesktopMenu.defaultProps = {
  items: [],
  onClick: () => {},
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.secondary.contrastText,
  },
}));
