import {
  Box, Collapse, IconButton, List, ListItem, ListItemText, Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { FiChevronDown, FiTag } from 'react-icons/fi';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import trim from 'lodash/trim';
import Link from '../../atoms/Link';
import MenuItemBadge from './MenuItemBadge';
import Image from '../../atoms/Image';
import { navigationItemShape } from './propTypes';

export default function MobileMenuItem(props) {
  const { item, level, onClick } = props;
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);
  const hasItems = item.items?.length > 0;
  const markedItems = [74, 75, 76, 77];

  const handleToggleItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setExpanded(!isExpanded);
  };

  return (
    <>
      <Link href={item.path} legacyBehavior>
        <ListItem
          button
          className={clsx(
            classes.root,
            {
              [classes.audienceSale]: item.highlighting === 'promotion',
            },
          )}
          component="a"
          onClick={onClick}
        >
          <ListItemText
            primaryTypographyProps={{
              className: clsx({
                [classes.menuItemLevel1]: level === 1,
                [classes.audienceBold]: item.highlighting === 'bold',
                [classes.audienceSale]: item.highlighting === 'promotion',
              }),
            }}
          >
            <Box className={classes.carBrandsContainer}>
              <Typography className={classes.menuTitle}>
                {trim(item.title)}
              </Typography>
              {markedItems.includes(item.id) && (
              <>
                <Image
                  alt="Hyundai"
                  className={clsx(
                    classes.carBrandLogo,
                    classes.carBrandLogoHyundai,
                  )}
                  isFluid
                  src="/images/car-brands/hyundai-motor-company-logo-2.svg"
                />
                <Image
                  alt="MG Motor"
                  className={clsx(
                    classes.carBrandLogo,
                    classes.carBrandLogoMG,
                  )}
                  isFluid
                  src="/images/car-brands/mgmotors-logo.svg"
                />
                <Image
                  alt="Mitsubishi"
                  className={clsx(
                    classes.carBrandLogo,
                    classes.carBrandLogoMitsubishi,
                  )}
                  isFluid
                  src="/images/car-brands/mitsubishi-logo.svg"
                />
              </>
              )}
            </Box>

          </ListItemText>
          {item.props?.badge && (
            <MenuItemBadge
              icon={item.props.badgeIcon}
              label={item.props.badge}
            />
          )}
          {hasItems && (
            <IconButton onClick={handleToggleItem}>
              <FiChevronDown className={clsx(classes.menuItemIcon, {
                [classes.menuItemIconExpanded]: isExpanded,
              })}
              />
            </IconButton>
          )}
          {item.highlighting === 'promotion' && (
            <IconButton>
              <FiTag className={clsx(classes.menuItemIcon, classes.audienceSale)} />
            </IconButton>
          )}
        </ListItem>
      </Link>
      {hasItems && (
        <Collapse in={isExpanded} timeout="auto">
          <Box mb={3} pl={4}>
            <List component="div" disablePadding>
              {item.items.map((subItem) => (
                <MobileMenuItem
                  key={subItem.path}
                  item={subItem}
                  level={level + 1}
                  onClick={onClick}
                />
              ))}
            </List>
          </Box>
        </Collapse>
      )}
    </>
  );
}

MobileMenuItem.propTypes = {
  item: navigationItemShape.isRequired,
  level: PropTypes.number.isRequired,
  // Events
  onClick: PropTypes.func,
};
MobileMenuItem.defaultProps = {
  onClick: () => {},
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  menuItemLevel1: {
    fontWeight: '700',
  },
  menuItemIcon: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.complex,
    }),
  },
  menuItemIconExpanded: {
    transform: 'rotate(180deg)',
  },
  audienceBold: {
    fontWeight: '900',
  },
  audienceSale: {
    fontWeight: '900',
    color: theme.palette.primary.main,
  },
  carBrandsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  menuTitle: {
    marginRight: theme.spacing(1),
  },
  carBrandLogo: {
    marginRight: theme.spacing(1),
  },
  carBrandLogoHyundai: { width: 24 },
  carBrandLogoMG: { width: 15 },
  carBrandLogoMitsubishi: { width: 15 },
}));
