import * as yup from 'yup';

import {
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import useContentElement from '../../hooks/useContentElement';

export default function RibbonElement(props) {
  const { data, ...otherProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { elementData } = useContentElement(
    data,
    RibbonElement.dataSchema
  );

  const {
    text,
    theme: themeOverride,
    position_y
  } = elementData;

  return (
    <div className={classes.root}>
      <div style={{ height: `${position_y ?? 80}%` }}></div>
      <div
        className={clsx(classes.container, {
          [classes.theme_hyundai]: themeOverride === 'hyundai'
        })}
        {...otherProps}
      >
        <Typography
          variant={isMobile ? 'subtitle2' : 'h6'}
          component="div"
          dangerouslySetInnerHTML={{
            __html: text && text.replaceAll
              ? text.replaceAll('\n', '<br />')
              : text
          }}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  container: {
    maxWidth: '80%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  theme_hyundai: {
    backgroundColor: '#012c5f'
  }
}));

RibbonElement.typeName = 'RibbonElement'; // Strapi element type
RibbonElement.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
    theme: PropTypes.string,
    position_y: PropTypes.number
  }).isRequired,
};
RibbonElement.defaultProps = {
  data: {
    theme: 'primary',
    position_y: 80
  }
};
RibbonElement.dataSchema = yup.object().shape({
  text: yup.string(),
  theme: yup.string().nullable(),
  position_y: yup.number().nullable()
});

RibbonElement.graphQlSchema = `
  id
  text
  theme
  positionY
`;
