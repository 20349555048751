// eslint-disable-next-line max-classes-per-file
import { isClientSide, isServerSide } from '@lib/utils/environment';
import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { gdprConfig } from '../constants';

class Watcher {
  constructor(handler) {
    this.handler = handler;
  }

  update(obj, name, data) {
    this.handler(obj, name, data);
  }
}

class KlaroServer {
  // eslint-disable-next-line class-methods-use-this
  getManager() {
    return {
      consents: {},
      watch: () => { },
      unwatch: () => { },
    };
  }
}

async function setup() {
  if (isServerSide()) {
    return Promise.resolve(new KlaroServer());
  }

  if (window.klaro) {
    return Promise.resolve(window.klaro);
  }

  const i18n = await import('i18next');
  i18n.default.on('languageChanged', () => {
    if (window.klaroConfig) {
      window.klaroConfig.lang = i18n.default.language;
      window.klaro.setup(window.klaroConfig);
    }
  });

  return import('klaro').then((klaro) => {
    window.klaro = klaro;
    window.klaroConfig = gdprConfig;

    klaro.setup(gdprConfig);
    return klaro;
  });
}

export async function getConsentAsync(name) {
  const klaro = await setup();

  const consentManager = klaro.getManager();
  return consentManager.consents[name];
}

export async function addListenerAsync(handler) {
  const klaro = await setup();

  const consentManager = klaro.getManager();
  const watcher = new Watcher(handler);

  consentManager.watch(watcher);

  return () => {
    consentManager.unwatch(watcher);
  };
}

export async function updateDomAsync() {
  const klaro = await setup();

  klaro.render(gdprConfig);
}

export function useConsent(serviceName) {
  const [hasConsent, setConsent] = useState(null);

  useEffect(() => {
    let listener = () => { };

    addListenerAsync((obj, name, data) => {
      if (name !== 'applyConsents') {
        return;
      }

      getConsentAsync(serviceName).then((consent) => {
        setConsent(consent);
      });
    }).then((remove) => {
      listener = remove;

      getConsentAsync(serviceName).then((consent) => {
        setConsent(consent);
      });
    });

    return function () {
      listener();
    };
  }, []);

  return hasConsent;
}

export function useConsentManager() {
  return {
    changeConsent: async () => {
      if (isClientSide()) {
        const element = document.getElementById('klaro');

        if (element) element.style.display = 'block';
      }

      const klaro = await setup();
      klaro.show();
    },
  };
}

export function useConsentListener(handler) {
  useEffect(() => {
    let listener = () => { };

    addListenerAsync((obj, name, data) => {
      handler(obj, name, data);
    }).then((remove) => {
      listener = remove;
    });

    return function () {
      listener();
    };
  }, []);
}

export function useHideConsentOnPrivacyPage(paths = []) {
  const router = useRouter();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const match = Boolean(paths.find((p) => router.asPath.startsWith(p)));
      const element = document.getElementById('klaro');

      if (!element) return;

      element.style.display = match ? 'none' : 'block';
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [router.asPath]);
}

export default setup();
