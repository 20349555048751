import React from 'react';
import { Chip } from '@material-ui/core';
import {
  FiBatteryCharging, FiHelpCircle, FiLoader, FiPlus, FiStar, FiSun, FiTag, FiZap,
} from 'react-icons/fi';
import { makeStyles } from '@material-ui/styles';

export default function MenuItemBadge(props) {
  const { label, icon } = props;
  const classes = useStyles();

  return (
    <Chip
      className={classes.root}
      color="primary"
      icon={icon ? mapBadgeIcon(icon) : null}
      label={label}
      size="small"
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    fontSize: '0.65rem',
    height: 18,
  },
}));

function mapBadgeIcon(icon) {
  switch (icon) {
    case 'plus':
      return <FiPlus />;
    case 'help':
      return <FiHelpCircle />;
    case 'loader':
      return <FiLoader />;
    case 'star':
      return <FiStar />;
    case 'tag':
      return <FiTag />;
    case 'sun':
      return <FiSun />;
    case 'zap':
      return <FiZap />;
    case 'battery':
      return <FiBatteryCharging />;
    default:
      return null;
  }
}
