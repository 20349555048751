import { List, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import LanguagePopover from '@components/organisms/LanguagePopover';
import { useState } from 'react';
import { navigationItemShape } from './propTypes';
import MobileMenuItem from './MobileMenuItem';

export default function MobileMenu(props) {
  const {
    items, onClick, locales, pageUrls,
  } = props;
  const classes = useStyles();
  const [openPopover, setOpenPopover] = useState(false);

  const handleClosePopover = () => {
    setOpenPopover(false);
    onClick();
  };
  const handleOpen = () => {
    setOpenPopover(true);
  };

  return (
    <List
      className={classes.root}
      component="nav"
    >
      <Hidden mdUp>
        <LanguagePopover
          handleOpen={handleOpen}
          locales={locales ?? []}
          onClose={handleClosePopover}
          open={openPopover}
          pageUrls={pageUrls}
        />
      </Hidden>
      {items.map((item) => (
        <MobileMenuItem
          key={item.path}
          item={item}
          level={1}
          onClick={onClick}
        />
      ))}
    </List>
  );
}

MobileMenu.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};
MobileMenu.defaultProps = {
  items: [],
  onClick: () => {},
};

const useStyles = makeStyles(() => ({
  root: {
  },
}));
