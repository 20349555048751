import {
  Box, Divider, Grid, Hidden, List, ListItem, ListItemAvatar, ListItemText, Typography,
} from '@material-ui/core';
import { LinkElement } from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import ModelName from '@components/vehiclefleet/ModelName';
import { megaMenuGroupShape, megaMenuItemShape, megaMenuShape } from './propTypes';
import MenuItemBadge from './MenuItemBadge';
import Image from '../../atoms/Image';

export default function DesktopMenuItemMegaMenu(props) {
  const {
    item,
    onClick,
  } = props;
  const classes = useStyles();
  const hasItems = item.items.length > 0;
  const hasGroups = item.groups.length > 0;
  const shouldDivide = hasItems && hasGroups;

  return (
    <div className={classes.root}>
      <Grid
        alignItems="stretch"
        container
      >
        {hasGroups && (
          <Grid item xs="auto">
            {item.groups.map((group, index) => (
              <Group
                key={group.id}
                classes={classes}
                isLastGroup={index >= item.groups.length - 1}
                item={group}
                onClick={onClick}
              />
            ))}
          </Grid>
        )}
        {shouldDivide && (
          <Hidden>
            <Grid item xs="auto">
              <Divider orientation="vertical" />
            </Grid>
          </Hidden>
        )}
        {hasItems && (
          <Grid item xs="auto">
            <List component="div">
              {item.items.map((menuItem) => (
                <Item
                  key={menuItem.id}
                  classes={classes}
                  item={menuItem}
                  onClick={onClick}
                />
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

DesktopMenuItemMegaMenu.propTypes = {
  item: megaMenuShape,
  // Events
  onClick: PropTypes.func,
};
DesktopMenuItemMegaMenu.defaultProps = {
  item: {},
  onClick: () => {
  },
};

function Item(props) {
  const {
    classes,
    item,
    dense,
    onClick,
  } = props;
  const {
    title,
    badge,
    badgeIcon,
    image,
    link,
  } = item;

  const markedItems = [
    45, 46, 47, 48, 178, 176, 179, 182, 284, 281, 283, 282, 382, 383, 380, 381, 482, 481, 476, 474,
  ];

  if (!link) {
    console.log('Missing link object', item.id, item);
  }

  return (
    <LinkElement data={link} onClick={onClick}>
      <ListItem
        button
        className={clsx(item.cssClass, classes.item)}
        component="a"
        dense={dense}
      >
        {image && (
          <ListItemAvatar
            className={classes.itemImageContainer}
          >
            <Image
              alt=""
              className={classes.itemImage}
              isFluid
              isSquare
              src={(
                image.formats?.thumbnail.url
                ?? image.formats?.small.url
                ?? image.formats?.medium.url
                ?? image.formats?.large.url
                ?? image.url
              )}
            />
          </ListItemAvatar>
        )}
        <ListItemText>
          <Box className={classes.carBrandsContainer}>
            <ModelName className={classes.menuTitle} name={title} />
            {markedItems.includes(item.id) && (
              <>
                <Image
                  alt="Hyundai"
                  className={clsx(
                    classes.carBrandLogo,
                    classes.carBrandLogoHyundai,
                  )}
                  isFluid
                  src="/images/car-brands/hyundai-motor-company-logo-2.svg"
                />
                <Image
                  alt="MG Motor"
                  className={clsx(
                    classes.carBrandLogo,
                    classes.carBrandLogoMG,
                  )}
                  isFluid
                  src="/images/car-brands/mgmotors-logo.svg"
                />
                <Image
                  alt="Mitsubishi"
                  className={clsx(
                    classes.carBrandLogo,
                    classes.carBrandLogoMitsubishi,
                  )}
                  isFluid
                  src="/images/car-brands/mitsubishi-logo.svg"
                />
              </>
            )}
          </Box>
        </ListItemText>
        {badge && (
          <MenuItemBadge
            icon={badgeIcon}
            label={badge}
          />
        )}
      </ListItem>
    </LinkElement>
  );
}

Item.propTypes = {
  item: megaMenuItemShape,
  dense: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  // Events
  onClick: PropTypes.func,
};
Item.defaultProps = {
  item: {},
  dense: false,
  onClick: () => {
  },
};

function Group(props) {
  const {
    classes,
    item,
    isLastGroup,
    onClick,
  } = props;
  const {
    title,
    items,
  } = item;
  const columnSpan = items.length <= 1 ? 12 : 6;

  return (
    <>
      <Box className={clsx(item.css_class, classes.group)}>
        {title && (
          <Box px={2} py={1}>
            <Typography color="primary" variant="subtitle2">
              {title}
            </Typography>
          </Box>
        )}
        <Grid alignItems="center" container>
          {items.map((menuItem) => (
            <Grid
              key={item.id}
              item
              xs={columnSpan}
              zeroMinWidth
            >
              <Item
                classes={classes}
                dense
                item={menuItem}
                onClick={onClick}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {!isLastGroup && (
        <Box my={1}>
          <Divider />
        </Box>
      )}
    </>
  );
}

Group.propTypes = {
  item: megaMenuGroupShape,
  classes: PropTypes.object.isRequired,
  isLastGroup: PropTypes.bool.isRequired,
  // Events
  onClick: PropTypes.func,
};
Group.defaultProps = {
  item: {},
  onClick: () => {
  },
};

const useStyles = makeStyles((theme) => ({
  root: {},
  group: {
    maxWidth: 600,
  },
  item: {
    minWidth: 150,
  },
  itemImageContainer: {
    marginRight: theme.spacing(2),
  },
  itemImage: {
    width: 70,
  },
  carBrandsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  menuTitle: {
    marginRight: theme.spacing(1),
  },
  carBrandLogo: {
    marginRight: theme.spacing(1),
  },
  carBrandLogoHyundai: { width: 24 },
  carBrandLogoMG: { width: 15 },
  carBrandLogoMitsubishi: { width: 15 },
}));
