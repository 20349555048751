import {
  Box, List, ListItem, ListItemText,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';
import Link from '../../atoms/Link';

export default function DesktopMenuItemList(props) {
  const { items, onClick } = props;
  const classes = useStyles();

  return (
    <List
      className={classes.root}
    >
      {items.map((item) => (
        <Item
          key={item.path}
          classes={classes}
          item={item}
          level={1}
          onClick={onClick}
        />
      ))}
    </List>
  );
}

DesktopMenuItemList.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};
DesktopMenuItemList.defaultProps = {
  items: [],
  onClick: () => {},
};

function Item(props) {
  const {
    item, level, classes, onClick,
  } = props;
  const hasItems = item.items?.length > 0;

  return (
    <>
      <Link href={item.path} legacyBehavior>
        <ListItem
          button
          component="a"
          onClick={onClick}
        >
          <ListItemText
            primaryTypographyProps={{
              className: clsx({}),
            }}
          >
            {item.title}
          </ListItemText>
        </ListItem>
      </Link>
      {hasItems && (
        <Box mb={3} pl={4}>
          <List component="div" disablePadding>
            {item.items.map((subItem) => (
              <Item
                key={subItem.path}
                classes={classes}
                item={subItem}
                level={level + 1}
                onClick={onClick}
              />
            ))}
          </List>
        </Box>
      )}
    </>
  );
}

Item.propTypes = {
  item: navigationItemShape,
  level: PropTypes.number,
  classes: PropTypes.object.isRequired,
  // Events
  onClick: PropTypes.func,
};

Item.defaultProps = {
  item: {},
  level: 1,
  onClick: () => {},
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 300,
  },
}));
