import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  FiArrowDown,
  FiFacebook,
  FiInstagram,
  FiYoutube,
} from 'react-icons/fi';
import { SiTiktok } from 'react-icons/si';
import { makeStyles, useTheme } from '@material-ui/styles';

import { DateTime } from 'luxon';
import React from 'react';
import { TypographyElement } from '@plugins/next-cms-core';
import clsx from 'clsx';
import { gql, useQuery } from '@apollo/client';
import { useConsentManager } from '@lib/gdpr';
import { useTranslation } from 'react-i18next';
import FooterMenu from '../molecules/FooterMenu';
import Link from '../atoms/Link';
import BrandLogo from '../molecules/BrandLogo';
import Container from '../atoms/Container';

const basePadding = 80;
const gridBreakpoints = {
  xs: 12,
  sm: 6,
  md: 3,
  lg: 3,
};

export default function Footer({ data, config }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { changeConsent } = useConsentManager();

  const { error, data: additionalData } = useQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    throw error;
  }

  const { pageBottomConfig, navigationFooter = [] } = data ?? {};
  const { globalInfoText } = pageBottomConfig?.data?.attributes ?? {};
  const { bottomInfoText } = config ?? {};
  const { branches } = additionalData ?? { branches: [] };

  const handleChangeConsent = (event) => {
    event.preventDefault();
    event.stopPropagation();

    changeConsent();
  };

  return (
    <div className={classes.root}>
      <Container>
        {(globalInfoText || bottomInfoText) && (
          <Box className={clsx(classes.infoContainer, classes.textMuted)}>
            {bottomInfoText && (
              <TypographyElement
                color="inherit"
                component="p"
                data={{
                  display_variant: 'caption',
                  value: bottomInfoText,
                }}
                paragraph
              />
            )}
            {globalInfoText && (
              <TypographyElement
                color="inherit"
                component="p"
                data={{
                  display_variant: 'caption',
                  value: globalInfoText,
                }}
                paragraph
              />
            )}
          </Box>
        )}
        <Box mb={2}>
          <Grid container spacing={4}>
            <Grid
              item
              lg={gridBreakpoints.lg}
              md={gridBreakpoints.md}
              sm={gridBreakpoints.sm}
              xs={gridBreakpoints.xs}
            >
              <BrandLogo
                alt=""
                className={classes.brandLogo}
                // eslint-disable-next-line react/style-prop-object
                style="inverted"
              />
              <Box mt={3}>
                <a
                  href="https://www.provenexpert.com/csb-schimmel-automobile-gmbh/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                  rel="noreferrer"
                  target="_blank"
                  title="Kundenbewertungen &amp; Erfahrungen zu CSB Schimmel Automobile GmbH. Mehr Infos anzeigen."
                >
                  <img
                    alt="Erfahrungen &amp; Bewertungen zu CSB Schimmel Automobile GmbH"
                    className="img-fluid"
                    data-name="provenexpert"
                    data-src="https://images.provenexpert.com/2e/6e/66449c43267c6ff417912012d2b7/widget_square_200_de_0.png"
                    height="200"
                    loading="lazy"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    width="200"
                  />
                </a>
              </Box>
              <Box mt={1}>
                <Link href="https://www.facebook.com/csb.berlin/" legacyBehavior>
                  <IconButton
                    aria-label="Facebook"
                    color="secondary"
                    component="a"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <FiFacebook />
                  </IconButton>
                </Link>
                <Link href="https://www.youtube.com/channel/UC2vfkFLsmJEsHUuYkFp7UHA" legacyBehavior>
                  <IconButton
                    aria-label="Youtube"
                    color="secondary"
                    component="a"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <FiYoutube />
                  </IconButton>
                </Link>
                <Link href="https://www.instagram.com/schimmelautomobile/" legacyBehavior>
                  <IconButton
                    aria-label="Instagram"
                    color="secondary"
                    component="a"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <FiInstagram />
                  </IconButton>
                </Link>
                <Link href="https://www.tiktok.com/@schimmelautomobile" legacyBehavior>
                  <IconButton
                    aria-label="TikTok"
                    color="secondary"
                    component="a"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    <SiTiktok style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              lg={gridBreakpoints.lg}
              md={gridBreakpoints.md}
              sm={gridBreakpoints.sm}
              xs={gridBreakpoints.xs}
            >
              <div className={classes.menuContainer}>
                <Box mb={3} mt={1}>
                  <Typography
                    className={classes.menuTitle}
                    color="secondary"
                    component="div"
                    variant="h6"
                  >
                    <FiArrowDown />
                    {t('components.organisms.Footer.service')}
                  </Typography>
                </Box>
                <Box>
                  <FooterMenu
                    items={[
                      {
                        label: t('components.organisms.Footer.vehicleSearch'),
                        href: '/fahrzeugsuche',
                      },
                      {
                        label: t('components.organisms.Footer.workshop'),
                        href: '/werkstatt',
                      },
                      {
                        label: t('components.organisms.Footer.hyundai'),
                        href: '/hyundai',
                        className: 'hyundai_tag',
                      },
                      {
                        label: t('components.organisms.Footer.mgMotor'),
                        href: '/mg-motor',
                        className: 'mgmotor_tag',
                      },
                      {
                        label: t('components.organisms.Footer.mitsubishi'),
                        href: '/mitsubishi',
                        className: 'mitsubishi_tag',
                      },
                      {
                        label: t('components.organisms.Footer.fuso'),
                        href: '/fuso',
                        className: 'fuso_tag',
                      },
                      {
                        label: t('components.organisms.Footer.maxus'),
                        href: '/maxus',
                        className: 'maxus_tag',
                      },
                    ]}
                  />
                </Box>
              </div>
            </Grid>
            <Grid
              item
              lg={gridBreakpoints.lg}
              md={gridBreakpoints.md}
              sm={gridBreakpoints.sm}
              xs={gridBreakpoints.xs}
            >
              <div className={classes.menuContainer}>
                <Box mb={3} mt={1}>
                  <Typography
                    className={classes.menuTitle}
                    color="secondary"
                    component="div"
                    variant="h6"
                  >
                    <FiArrowDown />
                    {branches.length}
                    {' '}
                    {t('components.organisms.Footer.locations')}
                  </Typography>
                </Box>
                <Box>
                  <FooterMenu
                    items={[
                      {
                        label: 'Hohenschönhausen',
                        href: '/unternehmen/standorte/berlin-hohenschoenhausen',
                      },
                      {
                        label: 'Spandau',
                        href: '/unternehmen/standorte/berlin-spandau',
                      },
                      {
                        label: 'Schöneweide',
                        href: '/unternehmen/standorte/berlin-schoeneweide',
                      },
                      {
                        label: 'Bernau',
                        href: '/unternehmen/standorte/bernau',
                      },
                    ]}
                  />
                </Box>
              </div>
            </Grid>
            <Grid
              item
              lg={gridBreakpoints.lg}
              md={gridBreakpoints.md}
              sm={gridBreakpoints.sm}
              xs={gridBreakpoints.xs}
            >
              <div className={classes.menuContainer}>
                <Box mb={3} mt={1}>
                  <Typography
                    className={classes.menuTitle}
                    color="secondary"
                    component="div"
                    variant="h6"
                  >
                    <FiArrowDown />
                    {t('components.organisms.Footer.more')}
                  </Typography>
                </Box>
                <Box>
                  <FooterMenu
                    items={[
                      {
                        label: t('components.organisms.Footer.career'),
                        href: '/unternehmen/karriere',
                      },
                      {
                        label: t('components.organisms.Footer.contact'),
                        href: '/unternehmen/standorte',
                      },
                      {
                        label: t('components.organisms.Footer.dataProtection'),
                        href: '/datenschutz',
                      },
                      {
                        label: t('components.organisms.Footer.cookie'),
                        onClick: handleChangeConsent,
                      },
                      {
                        label: t('components.organisms.Footer.imprint'),
                        href: '/impressum',
                      },
                      {
                        label: t('components.organisms.Footer.vehicleRepairConditions'),
                        href: '/kfz-reparaturbedingungen',
                      },
                      {
                        label: t('components.organisms.Footer.newCars'),
                        href: '/verkaufsbedingungen-neuwagen',
                      },
                      {
                        label: t('components.organisms.Footer.usedCars'),
                        href: '/verkaufsbedingungen-gebrauchtwagen',
                      },
                      {
                        label: t('components.organisms.Footer.partsSalesConditions'),
                        href: '/teileverkaufsbedingungen',
                      },
                    ]}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
        <div className={classes.separator} />
        <Box className={classes.textMuted} mt={3}>
          <Typography color="inherit" component="p" variant="caption">
            &copy;
            {' '}
            {DateTime.local().year}
            {' '}
            {t('components.organisms.Footer.description')}
          </Typography>
          <Grid container spacing={isMobile ? 0 : 4}>
            {navigationFooter.map((item) => (
              <Grid key={item.uiRouterKey} item md="auto" xs={12}>
                <Link href={item.path}>
                  <Typography color="inherit" component="a" variant="caption">
                    {item.title}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

const SEPARATOR_STYLE = {
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: 'rgba(255,255,255,0.1)',
};
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.text.primary,
    paddingTop: basePadding / 2,
    paddingBottom: basePadding * 2,
  },
  textMuted: {
    color: theme.palette.primary.contrastText,
    opacity: 0.65,
  },
  separator: {
    ...SEPARATOR_STYLE,
  },
  infoContainer: {
    ...SEPARATOR_STYLE,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  brandLogo: {
    height: 58,
  },
  menuContainer: {
    paddingLeft: theme.spacing(1) + 20,
  },
  menuTitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
    transform: `translateX(-${theme.spacing(1) + 20}px)`,
  },
}));

const FETCH_ADDITIONAL_DATA = gql`
query {
  branches {
    data {
      id
      attributes {
        branchId
      }
    }
  }
}
`;
