import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMemo } from 'react';
import clsx from 'clsx';

export default function ModelName(props) {
  const {
    className,
    component,
    name,
    variant,
  } = props;
  const classes = useStyles();
  const html = useMemo(
    () => (isNModel(name)
      ? replaceTextWithSVG(name, variant)
      : name),
    [name, variant],
  );

  return (
    <Typography
      className={clsx(classes.subtitle, className)}
      component={component || 'div'}
      dangerouslySetInnerHTML={{ __html: html }}
      variant={variant}
    />
  );
}

export function isNModel(name) {
  return REGEX.test(name)
    && !name.includes('N-Line')
    && !name.includes('N Line');
}

function replaceTextWithSVG(content, variant) {
  const svgPath = '/images/hyundai-n-logo.svg';
  const { w, h } = VARIANT_MAP[variant || 'default'] || VARIANT_MAP.default;
  return content.replace(REGEX, `<img width="${w}" height="${h}" src="${svgPath}" alt="N" />`);
}

const useStyles = makeStyles(() => ({
  subtitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  },
}));

const REGEX = /(^|\s)N(\s|$)/;

const VARIANT_MAP = {
  default: {
    w: 40,
    h: 25,
  },
  h3: {
    w: 72,
    h: 45,
  },
};
