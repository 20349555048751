/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FiMapPin, FiPhoneCall, FiSearch, FiSettings,
} from 'react-icons/fi';
import { Hidden, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { trackEvent } from './Tracking';
import { zIndices } from '../../theme';
import Backdrop from '../atoms/Backdrop';
import Link from '../atoms/Link';
import ScrollToTopFab from '../molecules/ScrollToTopFab';
import WhatsappFab from '../molecules/WhatsappFab';

export default function FloatingActionBar(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Backdrop className={classes.container}>
        <Hidden lgUp>
          <Actions
            color="default"
            data={data}
          />
        </Hidden>
        <Hidden mdDown>
          <Actions
            color="secondary"
            data={data}
            isDesktop
          />
        </Hidden>
      </Backdrop>
    </div>
  );
}

FloatingActionBar.propTypes = {
  data: PropTypes.object,
};

FloatingActionBar.defaultProps = {
  data: null,
};

function Actions(props) {
  const { t } = useTranslation();
  const { data, color, isDesktop } = props;
  const {
    sidebarPhone,
    sidebarUrlLocations,
    sidebarUrlWorkshop,
    sidebarUrlCarsearch,
  } = data ?? {};

  return (
    <>
      {!isDesktop && (
        <span style={{ marginRight: 'auto' }}>
          <WhatsappFab
            iconButton
            phoneNumbers={data.whatsappNumbers}
          />
        </span>
      )}
      <span>
        <Link href={`tel:${sidebarPhone}`} legacyBehavior>
          <IconButton
            aria-label="Anruf"
            color={color}
            component="a"
            onClick={() => {
              trackEvent('Contact');
              trackEvent('ContactCustom', { channel: 'Phone', origin: 'Floating Action Bar' });
            }}
          >
            <FiPhoneCall />
          </IconButton>
        </Link>
        {isDesktop && (
          <Typography color="secondary" variant="caption">
            {t('components.organisms.FloatingActionBar.call')}
          </Typography>
        )}
      </span>
      <span>
        <Link href={sidebarUrlLocations ?? '#'} legacyBehavior>
          <IconButton
            aria-label="Standorte"
            color={color}
            component="a"
            onClick={() => {
              trackEvent('Contact');
              trackEvent('ContactCustom', { channel: 'Branches', origin: 'Floating Action Bar' });
            }}
          >
            <FiMapPin />
          </IconButton>
        </Link>
        {isDesktop && (
          <Typography color="secondary" variant="caption">
            {t('components.organisms.FloatingActionBar.locations')}
          </Typography>
        )}
      </span>
      <span>
        <Link href={sidebarUrlWorkshop ?? '#'} legacyBehavior>
          <IconButton
            aria-label="Werkstatt"
            color={color}
            component="a"
            onClick={() => {
              trackEvent('Contact');
              trackEvent('ContactCustom', { channel: 'WorkshopAppointment', origin: 'Floating Action Bar' });
            }}
          >
            <FiSettings />
          </IconButton>
        </Link>
        {isDesktop && (
          <Typography color="secondary" variant="caption">
            {t('components.organisms.FloatingActionBar.workshop')}
          </Typography>
        )}
      </span>
      <span>
        <Link href={sidebarUrlCarsearch ?? '#'} legacyBehavior>
          <IconButton
            aria-label="Fahrzeuge"
            color={color}
            component="a"
            onClick={() => {
              trackEvent('Contact');
              trackEvent('ContactCustom', { channel: 'Vehicles', origin: 'Floating Action Bar' });
            }}
          >
            <FiSearch />
          </IconButton>
        </Link>
        {isDesktop && (
          <Typography color="secondary" variant="caption">
            {t('components.organisms.FloatingActionBar.vehicles')}
          </Typography>
        )}
      </span>
      {!isDesktop && (
        <span style={{ marginLeft: 'auto' }}>
          <ScrollToTopFab iconButton />
        </span>
      )}
    </>
  );
}

Actions.propTypes = {
  color: PropTypes.string,
  isDesktop: PropTypes.bool,
  data: PropTypes.shape({
    sidebarPhone: PropTypes.string,
    sidebarUrlLocations: PropTypes.string,
    sidebarUrlWorkshop: PropTypes.string,
    sidebarIrlCarsearch: PropTypes.string,
  }),
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex: zIndices.floatingActionBar,
    pointerEvents: 'none',
    // Position: mobile
    bottom: 0,
    left: 0,
    width: '100%',
    height: 'auto',
  },
  container: {
    display: 'flex',
    pointerEvents: 'all',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  [theme.breakpoints.up('lg')]: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      // Position: desktop
      top: 0,
      right: 0,
      left: 'auto',
      bottom: 'auto',
      width: 'auto',
      height: '100%',
    },
    container: {
      flexDirection: 'column',
      backgroundColor: theme.palette.primary.light,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      '& > *': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& > *:not(:last-child)': {
        marginRight: 0,
        marginBottom: theme.spacing(1),
      },
    },
  },
}));
