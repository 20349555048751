import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function Backdrop(props) {
  const { className, children } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  );
}

Backdrop.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
Backdrop.defaultProps = {
  className: null,
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    '@supports (backdrop-filter: blur(20px))': {
      backdropFilter: 'saturate(180%) blur(20px)',
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
  },
}));
