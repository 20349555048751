import * as yup from 'yup';

import PropTypes from 'prop-types';

export default function ColorScheme() { }

const COLORS = [
  null,
  'default',
  'primary',
  'secondary',
  'tertiary',
  'quaternary',
  'light',
  'dark',
  'mitsubishiYellow',
];

ColorScheme.getColorScheme = (prop, defaultColorScheme = null) => {
  const color = prop?.color === 'default' ? null : prop?.color;
  return color || defaultColorScheme;
};

ColorScheme.propTypes = {
  color: PropTypes.oneOf(COLORS),
};
ColorScheme.defaultProps = {
  color: 'default',
};
ColorScheme.dataSchema = yup.object().shape({
  color: yup.string().nullable().oneOf(COLORS),
});
ColorScheme.graphQlSchema = `
color
`;
