import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { navigationItemShape } from './propTypes';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

export const MainMenuHeight = 30;

export default function MainMenu(props) {
  const {
    desktop,
    mobile,
    locales,
    items,
    onClick,
    pageUrls,
  } = props;
  const classes = useStyles();

  const menuItems = items.filter((item) => item.path !== '/' && item.props?.hidden !== 'all');
  const desktopItems = menuItems.filter((m) => m.props?.hidden !== 'desktop');
  const mobileItems = menuItems.filter((m) => m.props?.hidden !== 'mobile');

  const handleItemClick = (e) => {
    if (onClick) onClick(e);
  };

  return (
    <div className={clsx(classes.root, {
      [classes.rootDesktop]: desktop,
      [classes.rootMobile]: mobile,
    })}
    >
      {desktop && (
        <DesktopMenu
          classes={classes}
          items={desktopItems}
          onClick={handleItemClick}
        />
      )}
      {mobile && (
        <MobileMenu
          classes={classes}
          items={mobileItems}
          locales={locales}
          onClick={handleItemClick}
          pageUrls={pageUrls}
        />
      )}
    </div>
  );
}

MainMenu.propTypes = {
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};
MainMenu.defaultProps = {
  desktop: false,
  mobile: false,
  items: [],
  onClick: () => {},
};

const useStyles = makeStyles(() => ({
  root: {
  },
  rootMobile: {
  },
  rootDesktop: {
    borderTopColor: 'rgba(0, 0, 0, 0.05)',
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid',
  },
}));
